import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { getAllPollsApi } from '../../api/apiAnon';
import { handleAuthenticationError } from '../../userAuth';
import { FaCog, FaArrowLeft } from 'react-icons/fa';
import Popup from 'reactjs-popup';
import AccountManagement from '../account/AccountManagement';
import ChangePassword from '../account/ChangePassword';
import ProfileUpload from './ProfileUpload';

export default function TopBar() {
  const nav = useNavigate();
  const [polls, setPolls] = useState(null);
  const [isCardActive, setIsCardActive] = useState(false);
  const [isCardActive2, setIsCardActive2] = useState(false);
  const [showPassChange, setShowPassChange] = useState(false);
  const location = useLocation();

  const handleDeactivateCard = () => setIsCardActive(false);
  const handleDeactivateCard2 = () => setIsCardActive2(false);
  const changePass = () => setShowPassChange(!showPassChange);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getAllPollsApi('false');
        setPolls(response.data);
      } catch (error) {
        handleAuthenticationError(error);
        console.error('Error fetching polls:', error);
      }
    }
    void fetchData();
  }, []);

  const getUser = () => {
    const user = localStorage.getItem('email');
    return user ? user : null;
  };

  const handleActivateCard = () => {
    if (getUser()) {
      setIsCardActive(true);
    } else {
      nav('/signin');
    }
  };

  const handleActivateCard2 = () => {
    if (getUser()) {
      setIsCardActive2(true);
    } else {
      nav('/signin');
    }
  }
    
    const navButtons = [
        { path: '/', label: 'Home' },
        { path: '/explore', label: 'Explore'},
        { path: '/genomics', label: 'My DNA' },
        { path: '/my-tests', label: 'My Tests' },
        { path: '/about', label: 'About' },
    ];

  return (
    <>
      <Popup
        open={isCardActive}
        closeOnDocumentClick
        onClose={handleDeactivateCard}
        modal
        overlayStyle={{ background: 'transparent' }}
        contentStyle={{
          width: '300px',
          display: 'flex',
          padding: '20px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '64px',
          background: '#F0F0F0',
          boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)',
        }}
      >
        <>
          {!showPassChange ? (
            <>
              <h2 className="black">Settings</h2>
              <AccountManagement showPassChange={changePass} />
            </>
          ) : (
            <>
              <div
                onClick={() => setShowPassChange(false)}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <FaArrowLeft size={20} color="black" />
                <h2 className="black" style={{ margin: 0 }}>
                  Back
                </h2>
              </div>
              <ChangePassword />
            </>
            )}

            </>
        </Popup>
        <Popup
                    open={isCardActive2}
                    closeOnDocumentClick
                    onClose={handleDeactivateCard2}
                    modal
                    overlayStyle={{ background: 'transparent' }}
                    contentStyle={{
                        width: "500px",
                        display: 'flex',
                        padding: '20px',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '64px',
                        background: '#F0F0F0',
                        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)',
                    }}
        >
            <>
            { 
            <>
                <ProfileUpload></ProfileUpload>
            </>
            }

            </>
        </Popup>
        <Navbar expand="lg" className="bg-body-tertiary mb-4"
        style={{ position: 'fixed', width: '100%', zIndex: 1000, top: 0}}>
            <Container>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"  />
                <Navbar.Collapse className="justify-content-start">
                    <div>
                    {getUser() && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* Circle Profile Picture */}
                        <img
                        src={localStorage.getItem('pfp')}
                        alt="Profile"
                        style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            objectFit: 'cover',
                            marginRight: '10px',
                            cursor: "pointer"
                        }}
                        onClick={handleActivateCard2}
                        />
                        {/* Welcome Text */}
                        <h2 className="black" style={{ fontSize: '16px' }}>
                        {localStorage.getItem('name')}
                        </h2>
                    </div>
                    )}
                        {!getUser() &&
                            <>
                            <button className="light-button"><Link to="/signin" className="link">Log In</Link></button>
                            <div style={{width: '20px', display: 'inline-block' }}></div>
                            <button className="light-button"><Link to="/signup" className="link">Register</Link></button>
                            </>
                        }
                    </div>
                </Navbar.Collapse>

          <Navbar.Collapse id="responsive-navbar-nav">
            <div className="d-flex mx-auto justify-content-center gap-4">
              {navButtons.map((button, index) => (
                <button
                  key={index}
                  onClick={() => (window.location.href = button.path)}
                  className={`nav-button ${
                    location.pathname === button.path
                      ? 'nav-button-selected'
                      : 'nav-button-default'
                  }`}
                >
                  {button.label}
                </button>
              ))}
            </div>
            <div className="d-flex justify-content-end">
              <button
                onClick={handleActivateCard}
                className="settings-button"
                style={{
                  backgroundColor: '#f8f9fa',
                  border: '1px solid #ced4da',
                  borderRadius: '50%',
                  width: '48px',
                  height: '48px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '0',
                  cursor: 'pointer',
                  marginLeft: 'auto',
                }}
              >
                <FaCog size={24} color="#6c757d" />
              </button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
