import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';

// You'll need to save a Google logo image in your project
// For example in: src/assets/google-logo.png
import googleLogo from '../../assets/g-logo.png'; 

const CustomGoogleButton = ({ onLoginSuccess, onLoginError }) => {
  const login = useGoogleLogin({
    onSuccess: onLoginSuccess,
    onError: onLoginError || onLoginSuccess, // Use the same handler if no error handler
    flow: 'implicit' // This gets you a credential similar to the GoogleLogin component
  });

  return (
    <button 
      onClick={() => login()} 
      className="custom-google-btn"
      type="button"
    >
      <img 
        src={googleLogo} 
        alt="Google" 
        className="google-logo" 
      />
      <span>Continue with Google</span>
    </button>
  );
};

export default CustomGoogleButton;