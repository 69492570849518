import {useEffect, useState} from "react";
import {getPollResults} from "../../api/apiUser";
import {handleAuthenticationError} from "../../userAuth";
import "../../css/results.css";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import 'react-tooltip/dist/react-tooltip.css';
import Switch from "react-switch";
import { lazyNorm } from "../../utils";
import { ClipLoader } from "react-spinners";
import { ordinalSuffix } from "../../utils";

export default function ViewResult({short_title, title, description}) {
    const [residx, setResIdx] = useState(0);
    const [responses, setResponses] = useState([]);
    const [allScores, setAllScores] = useState([]);
    const [allNormedScores, setAllNormedScores] = useState([]);
    const [sampleSize, setSampleSize] = useState(0);
    const [dimensions, setDimensions] = useState(null);
    const [percentiles, setPercentiles] = useState(null);
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const handleToggle = (nextChecked) => {
        setChecked(nextChecked);
    };

    const computePercentile = (myScore, scores) => {
        const sortedScores = [...scores].sort((a, b) => a - b);
        const count = sortedScores.filter(score => score <= myScore).length;
        const percentile = count / sortedScores.length;
        return Math.floor(percentile * 100) / 100;
    };


    useEffect(() => {
        async function fetchPollResults() {
            try {
                const pollResults_ = await getPollResults(short_title);
                console.log('POLLRESULTS', pollResults_.data);
                setResIdx(pollResults_.data.responses.length-1);
                setChecked(false);
                setAllNormedScores(pollResults_.data.allNormedScores)
                setAllScores(pollResults_.data.allScores)
                setResponses(pollResults_.data.responses)
                setSampleSize(pollResults_.data.allScores.length)
                const dims = Object.keys(pollResults_.data.allScores)
                setDimensions(dims)
                let percs = {}
                console.log(residx)
                for (const dim of dims) {
                    percs[dim] =  computePercentile(pollResults_.data.responses[pollResults_.data.responses.length-1].total_score[dim], pollResults_.data.allScores[dim])
                }
                setPercentiles(percs)
                setLoading(false);

            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching results:', error);
            }
        }
        void fetchPollResults();
    }, [short_title]);
    const percentage = 50

    const handleSelectChange = (event) => {
        setResIdx(event.target.value-1);
        let percs = {}
        for (const dim of dimensions) {
            percs[dim] =  computePercentile(responses[event.target.value-1].total_score[dim], allScores[dim])
        }
        setPercentiles(percs)

        console.log("Selected number:", event.target.value); // Log the selected value
      };

    const purpleBar = (number) => {
      return (
        <div className="percentile-info">
          <div className="percentile-bar">
              <div className="percentile-fill" style={{ width: `${number}%` }} >
                <span className="percentile-marker">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                    <path d="M9.59808 10.5C8.44338 12.5 5.55662 12.5 4.40192 10.5L0.937822 4.5C-0.216877 2.5 1.2265 0 3.5359 0L10.4641 0C12.7735 0 14.2169 2.5 13.0622 4.5L9.59808 10.5Z" fill="#9C52F2"/>
                  </svg>
                </span>
              </div>
              <span className="percentile">{`${ordinalSuffix(number)}`}</span>
              <span>Percentile</span>
          </div>
        </div>
      )
    }

    if (!loading){
        if (!isMobile) {
            return (
            <>
                <div 
                    style={{ 
                        width: "17%", 
                        height: "20px", 
                        backgroundColor: "transparent", 
                        visibility: "hidden" 
                    }}>
                </div>
                <div className="results_title_card" style={{ textAlign: 'center', margin: '20px 0' }}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <h2 className="text-gradient" style={{fontSize: "28px" }}>{title}</h2>
                        <div style={{display:"flex",flexDirection: "row", gap:"20px"}}>
                            <h2 className="black" style={{fontSize: "19px", margin:"14px"}}>Response #:</h2>
                            <select
                                className="light-button"
                                onChange={handleSelectChange}
                                value={residx+1}
                                style={{
                                padding: "15px",
                                paddingLeft: "25px",
                                paddingRight: "25px",
                                borderRadius: "5px",
                                border: "1px solid #ccc",
                                backgroundColor: "#f9f9f9",
                                }}
                            >
                                {Array.from({ length: responses.length }, (_, i) => i + 1).map((num) => (
                                <option key={num} value={num}>
                                    {num}
                                </option>
                                ))}
                            </select>
                        </div>
                    
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <p style={{ fontSize: '16px', color: '#555' }}>{description}</p>
                        { responses[residx]?.normed_score && 
                            <div style={{display: "flex", flexDirection: "row",  alignItems: "center", gap: "45px" }}>
                                <h2 className="black" style={{fontSize: "20px"}}>
                                    Norming:
                                </h2>
                                <Switch
                                onChange={handleToggle}
                                checked={checked}
                                offColor="#888"
                                onColor="#0bf"
                                />
                            </div>
                        }
                    </div>
                </div>
                <div 
                    style={{ 
                        width: "17%", 
                        height: "20px", 
                        backgroundColor: "transparent", 
                        visibility: "hidden" 
                    }}>
                </div>
                <div className="results_score_container">
                {!checked && dimensions && percentiles && responses[residx] && dimensions.map((dimension) => 
                    <div className="results_title_card">
                        <div style = {{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
                            <h2 className="black" style={{fontSize: "20px"}}>{dimension}: {responses[residx].total_score[dimension]}</h2>
                            <h2 className="black" style={{fontSize: "20px"}}>You scored better than {(percentiles[dimension] * 100).toFixed(0)}% of takers!</h2>
                        </div>
                        <div>{purpleBar((percentiles[dimension] * 100).toFixed(0))}</div>
                    </div>
                )}
                {checked && dimensions && percentiles && responses[residx] && dimensions.map((dimension) => 
                    <div className="results_title_card">
                        <div style = {{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
                            <h2 className="black" style={{fontSize: "20px"}}>{dimension}: {responses[residx].normed_score[dimension]?.toFixed(2)}</h2>
                            <h2 className="black" style={{fontSize: "20px"}}>You scored better than {lazyNorm(responses[residx].normed_score[dimension]) * 100}% of the population!</h2>
                        </div>
                        <div>
                            {purpleBar(lazyNorm(responses[residx].normed_score[dimension]) * 100)}
                        </div>
                    </div>
                )}
              </div>
            </>    
            )
        }
        if (isMobile) {
            if (isMobile) {
                return (
                  <>
                    <div
                      style={{
                        width: "17%",
                        height: "20px",
                        backgroundColor: "transparent",
                        visibility: "hidden",
                      }}
                    ></div>
              
                    <div style={{ padding: "15px", display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
                      {/* Header Card */}
                      <div
                        style={{
                          width: "330px",
                          padding: "25px",
                          borderRadius: "40px",
                          background: "var(--MAIN-BG, #F0F0F0)",
                          boxShadow: "0px 5px 60px 30px rgba(0, 0, 0, 0.07)",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: "20px",
                        }}
                      >
                        <h2 className="text-gradient" style={{ fontSize: "28px", margin: 0 }}>
                          {title}
                        </h2>
                        <div style={{ width: "100%" }}>
                          <h2 className="black" style={{ fontSize: "19px", margin: "14px 0" }}>
                            Response #:
                          </h2>
                          <select
                            className="light-button"
                            onChange={handleSelectChange}
                            value={residx + 1}
                            style={{
                              padding: "15px 25px",
                              borderRadius: "5px",
                              border: "1px solid #ccc",
                              backgroundColor: "#f9f9f9",
                            }}
                          >
                            {Array.from({ length: responses.length }, (_, i) => i + 1).map(
                              (num) => (
                                <option key={num} value={num}>
                                  {num}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        <p style={{ fontSize: "16px", color: "#555", margin: 0 }}>
                          {description}
                        </p>
                        {responses[residx]?.normed_score && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: "45px",
                            }}
                          >
                            <h2 className="black" style={{ fontSize: "20px", margin: 0 }}>
                              Norming:
                            </h2>
                            <Switch
                              onChange={handleToggle}
                              checked={checked}
                              offColor="#888"
                              onColor="#0bf"
                            />
                          </div>
                        )}
                      </div>
              
                      {/* Results Cards */}
                      <div className="results_score_container" style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                        {!checked &&
                          dimensions &&
                          percentiles &&
                          responses[residx] &&
                          dimensions.map((dimension, idx) => (
                            <div
                              key={idx}
                              style={{
                                width: "330px",
                                padding: "25px",
                                borderRadius: "40px",
                                background: "var(--MAIN-BG, #F0F0F0)",
                                boxShadow: "0px 5px 60px 30px rgba(0, 0, 0, 0.07)",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                gap: "20px",
                              }}
                            >

                              <div>
                              {!checked && dimensions && percentiles && responses[residx] && dimensions.map((dimension) => 
                                  <div >
                                      <div style = {{display: "flex", flexDirection: "column", gap:"20px", justifyContent: "space-between"}}>
                                          <h2 className="black" style={{fontSize: "20px"}}>{dimension}: {responses[residx].total_score[dimension]}</h2>
                                          <div>{purpleBar((percentiles[dimension] * 100).toFixed(0))}</div>
                                      </div>
                                  </div>
                              )}
                              </div>
                            </div>
                          ))}
              
                        {checked &&
                          dimensions &&
                          percentiles &&
                          responses[residx] &&
                          dimensions.map((dimension, idx) => (
                            <div
                              key={idx}
                              style={{
                                width: "330px",
                                padding: "25px",
                                borderRadius: "40px",
                                background: "var(--MAIN-BG, #F0F0F0)",
                                boxShadow: "0px 5px 60px 30px rgba(0, 0, 0, 0.07)",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                gap: "20px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >

                                {checked && dimensions && percentiles && responses[residx] && dimensions.map((dimension) => 
                                    <div >
                                        <div style = {{display: "flex", flexDirection: "column", gap: "20px", justifyContent: "space-between"}}>
                                            <h2 className="black" style={{fontSize: "20px"}}>{dimension}: {responses[residx].normed_score[dimension]?.toFixed(2)}</h2>
                                            <div>
                                            {purpleBar(lazyNorm(responses[residx].normed_score[dimension]) * 100)}
                                            </div>
                                        </div>
                                        
                                    </div>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
              
                    <div
                      style={{
                        width: "17%",
                        height: "20px",
                        backgroundColor: "transparent",
                        visibility: "hidden",
                      }}
                    ></div>
                  </>
                );
              }
        }
          
    }
    else {
        return (
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", padding:"300px"}}>
                     <ClipLoader color="#36d7b7" size={50} />
            </div>
        );
    }
}
