export const sxCenter = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}

export const ordinalSuffix = (num) => {
    const j = num % 10;
    const k = num % 100;
    
    if (k >= 11 && k <= 13) {
      return num + "th";
    }
    
    switch (j) {
      case 1:
        return num + "st";
      case 2:
        return num + "nd";
      case 3:
        return num + "rd";
      default:
        return num + "th";
    }
}
  

export const lazyNorm = (score) => {
    if (score > 10) {
        score = (score-100)/15
    }
    // Constants for the approximation
    const a1 = 0.254829592;
    const a2 = -0.284496736;
    const a3 = 1.421413741;
    const a4 = -1.453152027;
    const a5 = 1.061405429;
    const p = 0.3275911;

    // Save the sign of score
    const sign = score < 0 ? -1 : 1;
    const x = Math.abs(score) / Math.sqrt(2);

    // Approximation of erf(x)
    const t = 1 / (1 + p * x);
    const erf = 1 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);

    // CDF formula for the normal distribution
    return (0.5 * (1 + sign * erf)).toFixed(2);
};

// Make id of given length - from stackoverflow
export function makeId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }

    return result;
}

export function makeMatrix(l) {
    //takes a list and makes it a matrix
    if (!Array.isArray(l[0])) return l.map( i => [i]);
    else return l;
}

export function randomString(len) {
    let str = "";                                // String result
    for (let i = 0; i < len; i++) {              // Loop `len` times
        let rand = Math.floor(Math.random() * 62); // random: 0..61
        const charCode = rand += rand > 9 ? (rand < 36 ? 55 : 61) : 48; // Get correct charCode
        str += String.fromCharCode(charCode);      // add Character to str
    }
    return str; // After all loops are done, return the concatenated string
}


// Extracts a number from a given string if one exists.
export function extractNumber(str) {
    // Regular expression to find 'q' followed by any number of digits
    const regex = /q(\d+)/;
    const match = str.match(regex);

    // If a match is found, parse the number and return it
    if (match) {
        return parseInt(match[1], 10); // match[1] is the first captured group, i.e., the digits
    }

    // If no number is found, return null or any default value you prefer
    return null;
}

Array.prototype.max = function () {
    return Math.max.apply(null, this);
};

Array.prototype.min = function () {
    return Math.min.apply(null, this);
};

export function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}