import { useState, useRef, useEffect } from 'react';

const BaseCardLong = ({ title, score, description, percentile }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef(null);
  
  useEffect(() => {
    // Check if screen width is below 450px
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 450);
    };
    
    // Initial check
    handleResize();
    
    // Add event listener
    window.addEventListener('resize', handleResize);
    
    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const getScore = (percentile) => {
    if (percentile >= 75) return "High";
    if (percentile <= 25) return "Low";
    return "Average";
  };
  
  return (
    <div
      className="base-card-long"
      onClick={() => setIsExpanded(!isExpanded)}
      style={{
        padding: '25px 25px',
        cursor: 'pointer',
        height: isExpanded ? `${contentRef.current?.scrollHeight + 100}px` : '80px', // +50px accounts for padding
        transition: 'all 0.3s ease-in-out',
        overflow: 'hidden'
      }}
    >
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: '30px' // Fixed height for the header area
      }}>
        {/* First container - Title */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%'
        }}>
          <h3 style={{ margin: 0 }}>{title}</h3>
        </div>
        {/* Second container - Score, Chevron */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: isMobile ? '15px' : '33px',
          height: '100%'
        }}>
          <span className="text-gradient" style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%'
          }}>
            <h4 style={{ margin: 0 }}>{getScore(percentile)}</h4>
          </span>
          <img
            src="/chevron.svg"
            alt="expand/collapse"
            style={{
              width: '24px',
              height: '24px',
              transition: 'transform 0.3s ease',
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
            }}
          />
        </div>
      </div>
      {/* Percentile bar - Visible in both states but marker only visible when expanded */}
      <div className="percentile-bar" style={{
        width: '100%',
        marginTop: isExpanded ? '26px' : '19px', // 10px extra spacing when expanded
        marginBottom: '20px',
        transition: 'margin-top 0.3s ease-in-out, opacity 0.3s ease-in-out'
      }}>
        <div
          className="percentile-fill"
          style={{
            width: `${percentile}%`
          }}
        >
          <div className="percentile-marker" style={{
            opacity: isExpanded ? 1 : 0,
            transition: 'opacity 0.3s ease'
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
              <path d="M9.59808 10.5C8.44338 12.5 5.55662 12.5 4.40192 10.5L0.937822 4.5C-0.216877 2.5 1.2265 0 3.5359 0L10.4641 0C12.7735 0 14.2169 2.5 13.0622 4.5L9.59808 10.5Z" fill="#9C52F2"/>
            </svg>
          </div>
        </div>
      </div>
      <div ref={contentRef} style={{ marginTop: '0', paddingBottom: '40px' }}>
        You score higher than <span style={{
          fontSize: '30px',
          fontWeight: 700,
          lineHeight: '33.6px',
          color: '#9C52F2',
        }}>{percentile}</span>% of people on this trait.
        <br/><br/>
        <span style={{
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '2em',
          color: 'grey',
        }}>About the trait:</span>
        <br/>{description}
      </div>
    </div>
  );
};

export default BaseCardLong;