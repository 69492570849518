import React, { useRef, useState } from 'react';
import PollCard from './PollCard';
import { useNavigate } from 'react-router-dom';
import Scrollspy from 'react-scrollspy';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { hasTakenPoll } from '../../api/apiUser';
import '../../css/global.css';
import { getToken } from '../../userAuth';

const PollList = ({ tags, pollcards }) => {
    const navigate = useNavigate();
    const [isCardActive, setIsCardActive] = useState(false);
    const [hasTaken, setHasTaken] = useState({});
    const [cardPoll, setCardPoll] = useState({});
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const handleActivateCard = () => setIsCardActive(true);
    const handleDeactivateCard = () => setIsCardActive(false);

    async function navigateToPoll(poll) {
        setCardPoll(poll);
        setSelectedTitle(poll.short_titles[0])
        const data = {short_titles: poll.short_titles, token: getToken()}
        const res = await hasTakenPoll(data);
        setHasTaken(res.data.message);
        handleActivateCard();
    }

    const handleTakeTest = () => {
        navigate(`/poll/${selectedTitle}`);
    };

    const handleLearnMore = () => {
        navigate(`/about`);
    };

    const handleResults = () => {
        navigate(`/view/${cardPoll.short_title}`);
    };

    const tagRefs = useRef({});

    if(!isMobile){
        return (
            <>
                <Popup
                    open={isCardActive}
                    closeOnDocumentClick
                    onClose={handleDeactivateCard}
                    modal
                    overlayStyle={{ background: 'transparent' }}
                    contentStyle={{
                        display: 'flex',
                        padding: '20px',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '64px',
                        background: '#F0F0F0',
                        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    {  cardPoll.short_titles &&
                    <div className="popup-card">
                        {hasTaken[cardPoll.short_titles] && (
                            <div className="lr-div">
                                <p className="text-gradient" style={{ fontSize: '24px' }}>
                                    You've already taken this test!
                                </p>
                                <div style={{width: "185px"}}></div>
                                <button className="fabulous-button" onClick={handleResults}>
                                    View Results
                                </button>
                            </div>
                        )}
                        <h2 className="black">{cardPoll.title}</h2>
                        <p className="body">Time to Complete: 15 min</p>
                        <p className="body">{cardPoll.details}</p>
                        { cardPoll.short_titles.length > 1 &&
                            <div>
                                <h2 className="black" style={{fontSize: "16px"}}>This test is offered in multiple formats:</h2>
                                <br></br>
                                <div style={{ display: "flex", gap: "10px" }}>
                                {cardPoll.short_titles.map((title) => (
                                <div
                                    key={title}
                                    onClick={() => setSelectedTitle(title)}
                                    style={{
                                    padding: "10px 20px",
                                    cursor: "pointer",
                                    border: "0px solid #ccc",
                                    width: "130px",
                                    height: "150px",
                                    borderRadius: "30px",
                                    backgroundColor: selectedTitle === title ? "#888" : "#E8E8E8", // Grey shades
                                    color: selectedTitle === title ? "#fff" : "#000", // White text when selected
                                    transition: "background-color 0.2s ease-in-out",
                                    }}
                                >
                                    <h2 style={{fontSize: "16px"}}>{title.includes("irt") ? "Adaptive" : "Full"}</h2>
                                    <p style={{flexWrap: "wrap"}}>{title.includes("irt") ? "Adjusts to your responses." : "The most accurate results."}</p>
                                </div>
                                ))}
                            </div>
                        </div>
                        }
                        <div className="popup-div">
                            <button className="fabulous-button" onClick={handleTakeTest}>
                                Take The Test
                            </button>
                            <button className="white-button" onClick={handleLearnMore}>Learn More</button>
                        </div>
                    </div>
                    }
                </Popup>
                <div className="poll-list-container-nbs">
                    <div className="tags-column">
                        <h2 className="sticky-header">
                            <Scrollspy
                                items={tags}
                                currentClassName="rainbow"
                                className="tags-list"
                            >
                                {tags.map((tag, index) => (
                                    <li key={index} className="tag-item">
                                        <a href={`#${tag}`} className="tag-link">
                                            {tag.charAt(0) + tag.slice(1).toLowerCase()}
                                        </a>
                                    </li>
                                ))}
                            </Scrollspy>
                        </h2>
                    </div>
                    <div className="polls-column">
                        {tags.map((tag, index) => (
                            <div
                                key={index}
                                id={tag}
                                ref={(el) => (tagRefs.current[tag] = el)}
                            >
                                <hr />
                                <div className="testflexbox">
                                    {pollcards
                                        .filter((pollcard) => pollcard.tag === tag)
                                        .map((pollcard) => (
                                            <PollCard 
                                                pollcard={pollcard} 
                                                navigateFunc={navigateToPoll} 
                                                key={pollcard.title} 
                                            />
                                        ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    }
    else {
        return (
            <div>
            <Popup
                    open={isCardActive}
                    closeOnDocumentClick
                    onClose={handleDeactivateCard}
                    modal
                    overlayStyle={{ background: 'transparent' }}
                    contentStyle={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '64px',
                        background: '#F0F0F0',
                        width: '450px',
                        height: 'auto',
                        minHeight: "225px",
                        padding: "25px",
                        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    {  cardPoll.short_titles &&
                    <div style={{width: "300px"}}>
                        {hasTaken[cardPoll.short_titles] && (
                            <div>
                                <p className="text-gradient" style={{ fontSize: '24px' }}>
                                    You've already taken this test!
                                </p>
                                <div style={{width: "100px"}}></div>
                            </div>
                        )}
                        <h2 className="black" style={{fontSize: "18px"}}>{cardPoll.title}</h2>
                        <p className="body">{cardPoll.details}</p>
                        { cardPoll.short_titles.length > 1 &&
                            <div>
                                <h2 className="black" style={{fontSize: "16px"}}>This test is offered in multiple formats:</h2>
                                <br></br>
                                <div style={{ display: "flex", gap: "10px" }}>
                                {cardPoll.short_titles.map((title) => (
                                <div
                                    key={title}
                                    onClick={() => setSelectedTitle(title)}
                                    style={{
                                    padding: "10px 20px",
                                    cursor: "pointer",
                                    border: "0px solid #ccc",
                                    width: "130px",
                                    height: "150px",
                                    borderRadius: "30px",
                                    backgroundColor: selectedTitle === title ? "#888" : "#E8E8E8", // Grey shades
                                    color: selectedTitle === title ? "#fff" : "#000", // White text when selected
                                    transition: "background-color 0.2s ease-in-out",
                                    }}
                                >
                                    <h2 style={{fontSize: "16px"}}>{title.includes("irt") ? "Adaptive" : "Full"}</h2>
                                    <p style={{flexWrap: "wrap"}}>{title.includes("irt") ? "Adjusts to your responses." : "The most accurate results."}</p>
                                </div>
                                ))}
                            </div>
                        </div>
                        }
                        <div className="popup-div">
                            <button className="fabulous-button" onClick={handleTakeTest}>
                                Take The Test
                            </button>
                            <button className="white-button" onClick={handleLearnMore}>Learn More</button>
                        </div>
                    </div>
                    }
                </Popup>
                <div>
                    <h2 style={{paddingLeft: "25px"}}>Explore Tests</h2>
                    {tags.map((tag, index) => (
                        <div
                            key={index}
                            id={tag}
                            ref={(el) => (tagRefs.current[tag] = el)}
                        >
                            <hr />
                            <div style={{display: "flex", flexDirection: "column", gap: "16px", paddingLeft: "25px"}}>
                                {pollcards
                                    .filter((pollcard) => pollcard.tag === tag)
                                    .map((pollcard) => (
                                        <PollCard 
                                            pollcard={pollcard} 
                                            navigateFunc={navigateToPoll} 
                                            key={pollcard.title} 
                                        />
                                    ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
};

export default PollList;