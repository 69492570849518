import React, { useState, useEffect } from 'react';
import '../css/homepage.css';
import '../css/genomics.css';
import { Link, useNavigate } from 'react-router-dom';
import BaseCardLong from './common/BaseCardLong';
import traitDescriptions from '../data/traitDescriptions.json';
import CircleAnimation from './animations/circleanimation';  // Note the lowercase filename

export default function NewHome() {
  const [selectedTrait, setSelectedTrait] = useState(null);

  const handleTraitClick = (trait) => {
    setSelectedTrait(trait);
  };

  const handleClosePopup = () => {
    setSelectedTrait(null);
  };

  const renderTraitPopup = () => {
    if (!selectedTrait) return null;

    const traitInfo = traitDescriptions[selectedTrait.TRAIT];
    
    // Split references by newlines and render each as a separate paragraph
    const references = traitInfo.reference.split('\n\n').map((ref, index) => (
      <p 
        key={index}
        style={{ 
          fontStyle: 'italic', 
          fontSize: '0.9em', 
          color: '#6b7280',
          marginBottom: index < traitInfo.reference.split('\n\n').length - 1 ? '12px' : '0'
        }}
      >
        {ref}
      </p>
    ));

    return (
      <div className="popup-overlay">
        <div className="trait-popup">
          <h2>{selectedTrait.TRAIT}</h2>
          <div className="trait-content">
            <div className="percentile-info">
              <div className="percentile-bar">
                <div
                  className="percentile-fill"
                  style={{ width: `${parseInt(selectedTrait.PERCENTILE)}%` }}
                >
                  <span className="percentile-marker">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                      <path d="M9.59808 10.5C8.44338 12.5 5.55662 12.5 4.40192 10.5L0.937822 4.5C-0.216877 2.5 1.2265 0 3.5359 0L10.4641 0C12.7735 0 14.2169 2.5 13.0622 4.5L9.59808 10.5Z" fill="#9C52F2"/>
                    </svg>
                  </span>
                </div>
                <span className="percentile">{selectedTrait.PERCENTILE}th</span>
                <span>Percentile</span>
              </div>
            </div>
            <div className="trait-information">
              <p style={{ marginBottom: '20px' }}>{traitInfo.description}</p>
              <div style={{ borderTop: '1px solid #e5e7eb', paddingTop: '15px' }}>
                {references}
              </div>
            </div>
          </div>
          <div className="trait-buttons">
            <button onClick={handleClosePopup} className="btn btn-default">
              See More Traits
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderTraitCards = () => {
    const traits = [
      { TRAIT: "Autism", PERCENTILE: "23" },
      { TRAIT: "Depression", PERCENTILE: "45" },
      { TRAIT: "Education Level", PERCENTILE: "51" },
      { TRAIT: "Height", PERCENTILE: "76" },
      { TRAIT: "Intercranial Volume", PERCENTILE: "71" },
      { TRAIT: "IQ", PERCENTILE: "62" },
      { TRAIT: "Neuroticism", PERCENTILE: "16" },
      { TRAIT: "Schizophrenia", PERCENTILE: "35" },
      // Duplicate traits for continuous scrolling
      { TRAIT: "Autism", PERCENTILE: "23" },
      { TRAIT: "Depression", PERCENTILE: "45" },
      { TRAIT: "Education Level", PERCENTILE: "51" },
      { TRAIT: "Height", PERCENTILE: "76" },
      { TRAIT: "Intercranial Volume", PERCENTILE: "71" },
      { TRAIT: "IQ", PERCENTILE: "62" },
      { TRAIT: "Neuroticism", PERCENTILE: "16" },
      { TRAIT: "Schizophrenia", PERCENTILE: "35" },
    ];

    return (
      <div style={{
        width: '100%',
        height: '340px',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '40px',
        gap: '10px',
        order: 1,
        margin: '-10px 0 0 0'
      }}>
        <div style={{
          display: 'flex',
          gap: '28px',
          animation: 'scroll 20s linear infinite',
          paddingTop: '42px'
        }}>
          {traits.map((trait, index) => (
            <div
              key={`${trait.TRAIT}-${index}`}
              className="base-card result-card"
              style={{
                minWidth: '268px',
                cursor: 'pointer'
              }}
              onClick={() => handleTraitClick(trait)}
            >
              <h3>{trait.TRAIT}</h3>
              <div className="percentile-bar">
                <div
                  className="percentile-fill"
                  style={{ width: `${trait.PERCENTILE}%` }}
                >
                  <span className="percentile-marker">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                    <path d="M9.59808 10.5C8.44338 12.5 5.55662 12.5 4.40192 10.5L0.937822 4.5C-0.216877 2.5 1.2265 0 3.5359 0L10.4641 0C12.7735 0 14.2169 2.5 13.0622 4.5L9.59808 10.5Z" fill="#9C52F2"/>
                  </svg>
                  </span>
                </div>
                <div className="span-container">
                  <span className="percentile">{trait.PERCENTILE}th</span>
                  <span className="percentile-text">Percentile</span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <style>
          {`
            @keyframes scroll {
              0% {
                transform: translateX(0);
              }
              100% {
                transform: translateX(-50%);
              }
            }
            .fade-edge-left {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 100px;
              background: linear-gradient(to right, #f8f9fa 0%, rgba(240, 240, 240, 0) 100%);
              z-index: 2;
              pointer-events: none;
            }
            .fade-edge-right {
              position: absolute;
              right: 0;
              top: 0;
              height: 100%;
              width: 100px;
              background: linear-gradient(to left, #f8f9fa 0%, rgba(240, 240, 240, 0) 100%);
              z-index: 2;
              pointer-events: none;
            }
          `}
        </style>
        <div className="fade-edge-left" />
        <div className="fade-edge-right" />
      </div>
    );
  };

  const CardCluster = () => {
    return (
      <div style={{ 
        position: 'relative',
        width: '488px',
        height: '460px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }}>
        {/* Background IQ Test Card */}
        <div className="base-card-mini" style={{
          position: 'absolute',
          width: '268px',
          height: '200px',
          right: '220px',
          top: '55px',
          zIndex: 1,
          transition: 'all 0.3s ease-in-out'
        }}>
          <img 
            src="/home_card_three.jpeg"
            alt="IQ Test"
            style={{
              width: '228px',
              height: '90px',
              borderRadius: '20px',
              marginBottom: '15px',
              objectFit: 'cover'
            }}
          />
          <h3>IQ Test</h3>
          <p>Discover your IQ score.</p>
        </div>
  
        {/* Big 5 Personality Card */}
        <div className="base-card-mini" style={{
          position: 'absolute',
          width: '268px',
          height: '222px',
          right: '0px',
          top: '0px',
          zIndex: 2,
          transition: 'all 0.3s ease-in-out'
        }}>
          <img 
            src="/home_card_two.jpeg"
            alt="Big 5 Personality"
            style={{
              width: '228px',
              height: '90px',
              borderRadius: '20px',
              marginBottom: '15px',
              objectFit: 'cover'
            }}
          />
          <h3>Big 5 Personality</h3>
          <p>Find your personality characteristics.</p>
        </div>
  
        {/* Cultural Worldview Card */}
        <div className="base-card-mini" style={{
          position: 'absolute',
          width: '268px',
          height: '222px',
          right: '140px',
          bottom: '0px',
          zIndex: 2,
          transition: 'all 0.3s ease-in-out'
        }}>
          <img 
            src="/home_card_one.jpeg"
            alt="Cultural Worldview"
            style={{
              width: '228px',
              height: '90px',
              borderRadius: '20px',
              marginBottom: '15px',
              objectFit: 'cover'
            }}
          />
          <h3>Cultural Worldview</h3>
          <p>See how you align politically.</p>
        </div>
      </div>
    );
  };

  return (
      <div className="page-wrapper">
        <section className="scroll-section">
          <div className="hcontent-wrapper">
          <div className="landing-section">
            <div className="left-section">
              <h1 className="text-gradient title">
                Genotyper
              </h1>
              <h2 style={{ maxwidth: '757px', marginBottom: '10px' }}>
                A free tool to unlock the wealth of information hidden within your DNA.
              </h2>
              <p>
              Discover your unique genetic and psychological traits through professional-grade analysis that typically costs hundreds for free. Contribute to groundbreaking research while unlocking insights about who you truly are.
              </p>
              <div className='logogridbtn'>
              <Link
                to="/genomics"
                className="btn btn-default"
              >
                Get Started
              </Link>
              <div className="logo-grid">
                <img
                  src="/23andme_logo.svg"
                  alt="23andMe logo"
                  style={{ height: '50px', objectFit: 'contain' }}
                />
                <img
                  src="/ancestrydna_logo.svg"
                  alt="AncestryDNA logo"
                  style={{ height: '40px', objectFit: 'contain' }}
                />
                <img
                  src="/myheritage_logo.svg"
                  alt="MyHeritage DNA logo"
                  style={{ height: '20px', objectFit: 'contain' }}
                />
                <img
                  src="/decode.svg"
                  alt="MyHeritage DNA logo"
                  style={{ height: '30px', objectFit: 'contain' }}
                />
                {/* <img
                  src="/nebula.svg"
                  alt="Nebula Genomics logo"
                  style={{ height: '20px', objectFit: 'contain' }}
                />
                <img
                  src="/familytree.svg"
                  alt="familly Tree DNA logo"
                  style={{ height: '30px', objectFit: 'contain' }}
                />
                <img
                  src="/sequencing.webp"
                  alt="Sequencing logo"
                  style={{ height: '25px', objectFit: 'contain' }}
                />
                <img
                  src="/dante.png.webp"
                  alt="Dante Labs logo"
                  style={{ height: '35px', objectFit: 'contain' }}
                /> */}
                </div>
            </div>
          </div>
          
                      
          <div style={{
              position: 'absolute',
              top: '-100px',
              right: '-100px',
              width: '800px',
              height: '800px',
              zIndex: '10'
            }}>
              <CircleAnimation />
            </div>
        </div>
        <div style={{ zIndex: 10, position: 'relative',}}>
        {renderTraitCards()}
        </div>
        <div>
        {renderTraitPopup()}
        </div>
        </div>
        </section>


      <section className="scroll-section">
        <div className="mcontent-wrapper">
        <div className="imgtxtcontainer">
          <div>
            <div class="blobcontainer">
              <div class="blobs">
                <div class="blob a">a</div>
                <div class="blob b">b</div>
                <div class="blob c">c</div>
              </div>
            </div>
          </div>
          <div className="contentcontainer">
            <div className="textcontainer">
              <h2>Traits we analyze</h2>
            </div>
            <div className="flexcolumn">
              <div className="textcontainer">
                  <h4>Psychological</h4>
                  <p>Anxiety</p>
                  <p>Autism</p>
                  <p>Depression</p>
                  <p>Intelligence</p>
                  <p>Neuroticism</p>
                  <p>Schizophrenia</p>
              </div>
              <div className="textcontainer">
                  <h4>Physical</h4>
                  <p>Height</p>
                  <p>Brain Size</p>
              </div>
              <div className="textcontainer">
                  <h4>Lifestyle</h4>
                  <p>Education Level</p>
                  <p>Income</p>
              </div>
            </div>
          </div>

        </div>
        </div>
      </section>

      <section className="scroll-section">
        <div className="mcontent-wrapper">
        <div className="imgtxtcontainer">

          <div className="imgcontainer">
          <img
            src="/home_folk_one.jpeg"
            alt="A woman uploading data to a computer, in a scientific lab."
            style={{
              objectFit: 'cover',
              loading: 'lazy',
            }}
          />
          </div>
          <div className="contentcontainer">
            <div className="textcontainer">
              <h2>Simply upload your data</h2>
              <p>
              Using cutting-edge analysis techniques, we examine your genome for free. We use the data you've already paid for from 23andMe, Ancestry, or any other DNA testing service.
              </p>
              <p>
              We analyze traits that others don't, and we do so in greater detail, giving you a better understanding of who you are.
              </p>
            </div>
            <Link
                  to="/genomics"
                  className="btn btn-default"
                >
                  Get Started
            </Link>
          </div>
        </div>
        </div>
      </section>
      
      <section className="scroll-section">
        <div className="mcontent-wrapper">
        <div className="section-base">
          <div className="imgtxtcontainer r">
            <div className="contentcontainer">
              <div className="textcontainer">
                <h2>Or take a research grade IQ and personality test. Completely free.</h2>
                <p>
                  We curate scientifically-validated assessments used by leading researchers to measure your personality traits, cognitive abilities, and psychological characteristics with professional accuracy. Completely free!
                </p>
              </div>
              <Link
                to="/explore"
                className="btn btn-default"
              >
                Take a Test
              </Link>
            </div>
            <div style={{ display: 'flex'}}>
            <CardCluster />
            </div>
          </div>
        </div>
        </div>
      </section>

      <section className="scroll-section">
        <div className="mcontent-wrapper">
        <div className="section-base-vc">
          <h2 className="text-gradient">Get in depth results.</h2>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <BaseCardLong
              title="Verbal Intelligence"
              description="Verbal Intelligence reflects a person's ability to understand, process, and express themselves through language. People with high verbal intelligence tend to have strong communication skills, easily grasp complex concepts, and can articulate their thoughts clearly. They often excel at reading comprehension, writing, and verbal problem-solving. This trait is essential for academic success and effective communication in professional settings. Those with lower verbal intelligence may prefer visual or hands-on learning and might find it more challenging to express complex ideas through words."
              percentile={89}
            />
            <BaseCardLong
              title="Compassion"
              description="Compassion reflects how attuned someone is to the emotional needs and feelings of others. People with high compassion tend to be warm and caring, making others feel comfortable around them. They easily empathize with others' situations and often provide emotional support. This trait is crucial for building and maintaining positive relationships. Those with lower compassion tend to focus more on their own needs and may take a more logical, detached approach to others' problems."
              percentile={50}
            />
            <BaseCardLong
              title="Extroversion"
              description="Extroversion reflects how much someone gains energy from social interactions and external stimulation. People with high extroversion tend to be outgoing, enthusiastic, and energized by spending time with others. They often seek out social gatherings and thrive in group settings where they can engage in active discussions and activities. This trait influences how people recharge and interact with the world. Those with lower extroversion (introverts) tend to prefer quieter environments, need time alone to recharge, and may feel drained by extensive social interaction."
              percentile={20}
            />
          </div>
          <Link
            to="/explore"
            className="btn btn-default"
          >
            Get Started
          </Link>
        </div>
        </div>
      </section>

      <section className="scroll-section">
        <div className="mcontent-wrapper">
        <div className="imgtxtcontainer">
          <div className='imgcontainer'>
          <img
            src="/home_folk_two.jpeg"
            alt="group of researchers working together on analyzing genetic data"
          />
          </div>


          <div className="contentcontainer">
            <div className="textcontainer">
              <h2>Built by researchers. For you.</h2>
              <h4>Us</h4>
              <p>
              We're geneticists and data scientists who've created a platform that makes genetic data analysis accessible and understandable. 
              </p>
              <h4>Genotyper</h4>
              <p>
              Our tool transforms complex genetic data into clear insights using groundbreaking statistical analysis. Giving you unparalleled insights into your mind and genetic data.              </p>
              <h4>Privacy</h4>
              <p>
              Your privacy and security are paramount – all data is encrypted and stored in full HIPAA compliance, meeting the highest standards of data protection that research institutions trust.              </p>
            </div>
            <Link
              to="/about"
              className="btn btn-default"
            >
              Learn more about us
            </Link>
          </div>
        </div>
        </div>
      </section>
    </div>
  );
}