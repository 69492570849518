import React, {useEffect, useState} from 'react';
import {getAllPollCardsApi} from "../api/apiAnon";
import {getToken, handleAuthenticationError} from "../userAuth";
import {useNavigate} from "react-router-dom";
import {checkInfo} from "../api/apiUser";
import PollList from './common/PollList';

export default function Home() {
    const navigate = useNavigate();
    const [pollcards, setPollCards] = useState([]);
    const CACHE_KEY = 'pollCardsData';
    const [tags, setTags] = useState(['loading...']);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        async function fetchData() {
            try {
                const cachedData = localStorage.getItem(CACHE_KEY);
                if (cachedData) {
                    console.log('Using cached data');
                    let cached_pollcards = JSON.parse(cachedData);
                    let gottenTags = [...new Set(cached_pollcards.map(x => x.tag))];
                    setPollCards(cached_pollcards);
                    setTags(gottenTags)
                }

                const response = await getAllPollCardsApi('true');
                let gottenTags = [...new Set(response.data.map(x => x.tag))];
                setPollCards(response.data);
                setTags(gottenTags);
                localStorage.setItem(CACHE_KEY, JSON.stringify(response.data));
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching poll cards:', error);
            }
        }
        
        async function redirInfo() {
            try {
                const response = await checkInfo({token: getToken()});
                if (response.data.message === 'Info not found') {
                    navigate('/info');
                }
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching poll cards:', error);
            }
        }

        void fetchData();
        void redirInfo();
    }, []);
    if (!isMobile){
        return (
            <main className="home-container-nbs">
                <div className="content-wrapper-nbs">
                    <div className="hero-section">
                        <div className="hero-image">
                            <img 
                                src="/home_scientist.png" 
                                alt="A scientist sitting next to a computer looking at results on paper." 
                                style={{
                                    width: '400px',
                                    height: '400px',
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                }}
                            />
                        </div>
                        <div className="textcontainer" style={{justifyContent: 'center'}}>
                            <h1>Discover Yourself</h1>
                                <p>
                                Access validated psychological tests used by researchers worldwide. These reliable instruments help you understand your behavior, cognition, and personality.                            </p>

                                <p>
                                Get clear results with meaningful comparisons to see where you stand. Professional assessments, simplified for everyone.                            </p>
                        </div>
                    </div>
                    <div className="subjects-section">
                        <h1>Subjects</h1>
                    </div>
                    <PollList tags={tags} pollcards={pollcards}/>
                </div>
            </main>
        );
    }
    else {
        return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '80px'}}>
                <PollList tags={tags} pollcards={pollcards}/>
            </div>
        )
    }
}