import React, { useState } from 'react';
import { loginApi, loginGoogleApi } from "../../api/apiAuth";
import { Link } from "react-router-dom";
import { getAnonymousKey, loginUser } from "../../userAuth";
// Import your custom component instead of GoogleLogin
import CustomGoogleButton from './CustomGoogleButton'; 
import '../../css/Signin.css'
import '../../css/global.css'
import Swal from "sweetalert2";

export default function Signin({redirect = true}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [success, setSuccess] = useState('');

    function redirectPage() {
        if (redirect) {
            window.location = '/';
        } else {
            window.location.reload();
        }
    }

    const logIn = async () => {
        const data = {
            email: email,
            password: password,
            anon_key: getAnonymousKey(),
        };
        try {
            const ret = await loginApi(data);
            loginUser(email, ret.data);
            redirectPage();
        } catch (e) {
            if (e.response.data.message == "Account not verified") Swal.fire("Error", "Your account is not verified. Check your email.", "error");
            else Swal.fire("Error", "Login Failed! Email or password incorrect.", "error");
            console.log(e.message);
        }
    };

    const responseGoogle = async (response) => {
        // The response structure might be slightly different with useGoogleLogin
        const tokenResponse = response.credential || response.access_token;
        
        if (!tokenResponse) {
            Swal.fire("Error", "Unknown Google authentication error - try again or try alternative login.", "error");
            return
        }
        
        const data = {
            credential: response.credential,
            access_token: response.access_token,
            anon_key: getAnonymousKey(),
        }
        
        try {
            const ret = await loginGoogleApi(data);
            loginUser(ret.data.email, ret.data);
            redirectPage();
        } catch (e) {
            Swal.fire("Error", "Login Failed!", "error");
            console.log(e);
        }
    }

    return (
        <div className="container-div">
            <div className="signup-div">
                <h2 className="text-gradient">Log In</h2>
                <div className="Auth-form-container">
                    <form className="Auth-form">
                        <div className="Auth-form-content">
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control signin-input"
                                    placeholder="Email"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control signin-input"
                                    placeholder="Password"
                                    id="password"
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <br></br>
                            <div style={{width: "400px"}}>
                                <button
                                    type="submit"
                                    className="btn signin-btn"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        void logIn();
                                    }}
                                >
                                    Log In
                                </button>
                            </div>
                            {success && <p className="success-message">{success}</p>}
                        </div>
                    </form>
                </div>
                <div className="signup-link">
                    Don't have an account? <Link to="/signup">Sign Up</Link>
                </div>
                <div className="google-login">
                    {/* Replace GoogleLogin with your custom component */}
                    <CustomGoogleButton 
                        onLoginSuccess={responseGoogle} 
                        onLoginError={responseGoogle} 
                    />
                </div>
            </div>
        </div>
    );
}